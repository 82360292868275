import styled from "styled-components";

import { COLORS } from "../globals/colors";
import {
  DESKTOP_1200,
  DESKTOP_1024,
  TABLET_992,
  MOBILE_460,
  TABLET_768,
  TABLET_600,
  TABLET_800,
} from "../globals/sizes";

export const HeroContainer = styled.div`
  margin: 90px auto 0 auto;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1500px;
  width: 100%;
  overflow: hidden;
  background: ${COLORS.white};

  @media (max-width: ${DESKTOP_1024}px) {
      height: fit-content;
  }

  @media (max-width: ${MOBILE_460}px) {
    margin-top: 80px;
  }
`;

export const HeroPicture = styled.picture`
  @media (max-width: ${TABLET_800}px) {
    display: none;
  }
  & > img, 
  & > source {
    width: 100%;
    margin-right: 0%;
    object-fit: cover;
  }
`;

export const MobileDiv = styled.div`
  display: none;
  width: 100%;
  background: ${(props) =>
    props.bgImage
      ? `url(${props.bgImage}) 30% 45%/cover no-repeat`
      : `background: purple;`};
  @media (max-width: ${TABLET_800}px) {
    height: 400px;
    display: block;
  }
  @media (max-width: ${TABLET_600}px) {
    background: ${(props) =>
    props.bgImage && 
       `url(${props.bgMobileImage}) 10% 45%/cover no-repeat`};
  }
  
`;

export const InternalWrapper = styled.div`
  margin: 0;
  position: absolute;
  width: 100%;
  max-width: 1500px;

  @media (max-width: ${DESKTOP_1200}px) {
    max-width: 90%;
  }
  @media (max-width: ${TABLET_800}px) {
    max-width: 100%;
    margin: 0;
    flex: 1;
    position: relative;
  }
  @media (max-width: ${MOBILE_460}px) {
    max-width: 100%;
  }
`;

export const HeroTextDiv = styled.div`
  padding: 50px 30px;
  box-sizing: border-box;
  margin: auto;
  
  @media (max-width: 1600px) {
    width: 90%;
  }
    
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    padding: 36px 21px 44px;
    box-sizing: border-box;
  }
  @media (max-width: ${MOBILE_460}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  width: 12em;
  margin-bottom: 10px;
  color: ${COLORS.magenta};
  text-align: left;
  font: normal 57px/60px Roboto;;
  text-transform: uppercase;

  & > .nowrap {
    white-space: nowrap;
  }

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    margin: 14px 0;
    font: normal 47px/54px "Roboto";
  }
`;

export const PhoneAndParagraph = styled.div`
  @media (max-width: ${TABLET_800}px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Paragraph = styled.p`
  width: 41%;
  margin: 10px 0 20px;
  color: ${COLORS.grayText};
  font: 400 26px/34px "Roboto";

  @media (max-width: ${DESKTOP_1200}px) {
    width: 51%;
  }
  @media (max-width: ${TABLET_992}px) {
    font: normal 20px/28px "Roboto";
  }

  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    margin: 0;
  }
`;